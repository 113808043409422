<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <span>조회시간: {{ str_time }}</span>
          <button
            type="button"
            class="btn_sub1 refresh_btn"
            @click="refreshData"
          >
            재조회
          </button>
          <button class="btn_sub1" @click="fullScreen = !fullScreen">
            {{ fullScreen ? '닫기' : '전체화면' }}
          </button>
        </div>
        <div>
          <h5 class="title">출고지시서</h5>
          <div class="input_checkbox">
            <label
              for="checkbox"
              class="chk_box"
              :class="{ active: onlyNotOutput }"
            >
              <i class="fa fa-check"></i>
            </label>

            <input type="checkbox" id="checkbox" @click.prevent="chkOutput()" />
            <label for="checkbox" class="label_txt">출고 완료항목 제외</label>
          </div>
        </div>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>업체명</th>
              <th>제품</th>
              <th>출고지시 수량</th>
              <th>현재고(단위)</th>
              <th>출고완료 여부</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredRequest" :key="index">
              <td>
                {{
                  findInfoFromId(
                    companys,
                    findInfoFromId(sales, item.sales_id).company_id,
                  ).name
                }}
              </td>
              <td>
                {{ findInfoFromId(products, item.product_id).name }}
                {{
                  findInfoFromId(products, item.product_id).standard != ''
                    ? ` - ` + findInfoFromId(products, item.product_id).standard
                    : ''
                }}
              </td>
              <td>
                <b>{{ $makeComma(item.quantity) }}</b>
                {{
                  item.box_unit_id != null
                    ? findInfoFromId(boxs, item.box_unit_id).name
                    : findInfoFromId(
                        units,
                        findInfoFromId(products, item.product_id).stock_unit_id,
                      ).name
                }}
              </td>

              <td>
                <b>{{
                  $makeComma(
                    findInfoFromId(productTotalStock, item.product_id)
                      .stock_quantity,
                  )
                }}</b>
                {{
                  item.box_unit_id != null
                    ? findInfoFromId(boxs, item.box_unit_id).name
                    : findInfoFromId(
                        units,
                        findInfoFromId(products, item.product_id).stock_unit_id,
                      ).name
                }}
              </td>
              <td
                :class="{
                  complete: item.output_yn,
                  not_complete: !item.output_yn,
                }"
              >
                {{ item.output_yn ? 'Y' : 'N' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import {
  yyyymmdd,
  yyyy년MM월dd일HH시mm분ss초,
  // yyyy년MM월dd일,
  // HH시mm분,
  // X요일,
} from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      date: yyyy년MM월dd일HH시mm분ss초(new Date()),
      fullScreen: false,
      str_time: null,
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      onlyNotOutput: false,
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      sales: 'getSalesFromOutputRequest',
      newSalesSrc: 'getNewSalesFromOutput',
      boxs: 'getBoxs',
      productTotalStock: 'getProductTotalStock',
    }),
    filteredRequest() {
      if (this.sales != undefined) {
        let salesClone = this.lodash.clonedeep(this.sales);
        let newSalesSrcClone = this.lodash.clonedeep(this.newSalesSrc);

        let filterClone = salesClone
          .filter(
            x =>
              x.delivery_date == yyyymmdd(new Date()) &&
              x.product_list.length > 0,
          )
          .map(x => x.product_list)
          .reduce((a, b) => a.concat(b), []);

        if (!this.onlyNotOutput) {
          newSalesSrcClone = newSalesSrcClone
            .filter(x => x.sales.delivery_date == yyyymmdd(new Date()))
            .map(x => x.sales_product_list)
            .reduce((a, b) => a.concat(b), []);

          filterClone = filterClone.concat(newSalesSrcClone);
        }

        filterClone
          .sort((a, b) => a.product_id - b.product_id)
          .filter(x => x.request_out);

        return filterClone;
      }
      return [];
    },
  },
  methods: {
    chkOutput() {
      this.onlyNotOutput = !this.onlyNotOutput;
    },
    async refreshData() {
      const date = new Date();
      const today = yyyymmdd(date);
      date.setMonth(date.getMonth() - 1);

      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_SALES_TO_OUTPUT_REQUEST',
        '출고지시',
      );

      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
        {
          start: yyyymmdd(date),
          end: today,
        },
        '매출 내역',
      );

      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      // this.curr_date = yyyy년MM월dd일(new Date());
      // this.curr_time = HH시mm분(new Date());
      // this.curr_weekDay = X요일(new Date());
    },
  },
  async created() {
    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }

    await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');

    const date = new Date();
    const today = yyyymmdd(date);
    date.setMonth(date.getMonth() - 1);

    await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
      'FETCH_SALES_TO_OUTPUT_REQUEST',
      '출고지시',
    );

    await this.FETCH_WITH_PAYLOAD(
      'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
      {
        start: yyyymmdd(date),
        end: today,
      },
      '매출 내역',
    );

    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>

<style lang="scss" scoped></style>
